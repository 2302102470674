import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../components/header"
import TwoAndOne from "../../components/twoAndOne"
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      arbeitskreis: file(relativePath: { eq: "arbeitskreis.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [active, setActive] = useState("projekte")
  return (
    <Layout>
      <SEO title="Der Verein" />
      <Header siteTitle="Der Verein" />
      <section style={{ paddingBottom: "0" }} className="section">
        <div className="container">
          <Img fluid={data.arbeitskreis.childImageSharp.fluid} />
        </div>
      </section>
      <TwoAndOne>
        <div>
          <div className="content">
            <p>
              Seit seiner Gründung im Jahr 1991 hat der Arbeitskreis die
              Problematik des Pathologischen Glücksspiels in den Mittelpunkt
              seiner Arbeit gestellt. Dazu gehört neben der
              Beratungsstellentätigkeit auch die gesundheitspolitische Arbeit.
              Unser Ziel ist es, durch strukturelle Prävention, die Ausweitung
              des Glücksspiels einzudämmen und Elemente des Spielerschutzes zu
              etablieren. In diesem Aufgabenfeld führen wir Datenerhebungen und
              Untersuchungen des Glücksspielmarktes durch, gestalten
              Informations- und Schulungsveranstaltungen und vertreten die
              Interessen des Spielerschutzes auf gesundheitspolitischer Ebene.
              Als Verein haben wir die Möglichkeit bundesweit tätig zu werden.
            </p>
          </div>
          <div className="tabs">
            <ul>
              <li className={active === "projekte" && "is-active"}>
                <a onClick={() => setActive("projekte")}>Projekte</a>
              </li>
              <li
                className={
                  active === "schwerpunktberatungsstelle" && "is-active"
                }
              >
                <a onClick={() => setActive("schwerpunktberatungsstelle")}>
                  Schwerpunktberatungsstelle
                </a>
              </li>
              <li className={active === "fachberatungsstelle" && "is-active"}>
                <a onClick={() => setActive("fachberatungsstelle")}>
                  Fachberatungsstelle
                </a>
              </li>
            </ul>
          </div>

          {active === "schwerpunktberatungsstelle" && (
            <div className="content">
              <p>
                Der Arbeitskreis gegen Spielsucht e.V. ist nicht nur Träger der
                Fachberatungsstelle sondern auch der Schwerpunktberatungsstelle
                Glücksspielsucht – NRW – Standort Unna. Insgesamt gibt es in
                Nordrhein – Westfalen drei dieser Schwerpunktberatungsstellen.
                Die Schwerpunktberatungsstellen unterstützen die
                Landesfachstelle Glücksspielsucht in Herford bei der Umsetzung
                ihrer Aufgaben.
              </p>
              <strong>Aufgabenbereiche</strong>
              <ul>
                <li> Erhebung von Daten zum Glücksspielmarkt in NRW </li>
                <li>
                  Sensibilisierung und Information der Öffentlichkeit für die
                  Risiken des Glücksspiels
                </li>
                <li>Multiplikatorenschulung </li>
                <li>Stellungnahmen zur Entwicklung des Glücksspielmarktes </li>
                <li>
                  Beratung und Begleitung von Kommunen bzgl. der Entwicklung des
                  regionalen Glücksspielmarktes
                </li>
              </ul>
            </div>
          )}

          {active === "fachberatungsstelle" && (
            <div class="content">
              <p>
                Als Fachberatungsstelle für Pathologisches Glücksspiel und
                Medienabhängigkeit beraten und begleiten wir Betroffene und
                Angehörige ebenso wie Suchtgefährdete und andere Ratsuchende.
                Unser Aufgabenfeld reicht dabei von der Information, Beratung
                und Begleitung bis zur Vermittlung in stationäre Therapien und
                der Durchführung ambulanter Nachsorge.
              </p>
            </div>
          )}

          {active === "projekte" && (
            <div className="content">
              <ul>
                <li>
                  Erhebung von Daten zum Glücksspielmarkt in der Bundesrepublik
                </li>
                <li>
                  Erstellung von Publikationen zur Entwicklung des
                  Glücksspielmarktes
                </li>
                <li> Entwicklung struktureller Präventionsmaßnahmen</li>
                <li> Multiplikatorenschulung</li>
                <li>
                  Beratung und Begleitung von Kommunen bzgl. der Entwicklung des
                  regionalen Glücksspielmarktes
                </li>
                <li>
                  Kulturelle Projekte z.B. Literaturprojekt unter der
                  Schirmherrschaft der Drogenbeauftragten der Bundesregierung
                  Frau Mechthild Dyckmans
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="content">
          <div className="notification is-primary">
            <h5 className="title">Arbeitskreis gegen Spielsucht e.V.</h5>

            <p>
              <span class="icon">
                <i class="fas fa-home"></i>
              </span>
              Südring 31
              <br />
              <span class="icon">
                <i class="fas"></i>
              </span>
              59423 Unna
              <br />
              <a href="mailto:info@ak-spielsucht.de">
                <span class="icon">
                  <i class="fas fa-envelope"></i>
                </span>
                info@ak-spielsucht.de
              </a>
              <br />
              <span class="icon">
                <i class="fas fa-phone"></i>
              </span>
              02303 89669
            </p>
          </div>
        </div>
      </TwoAndOne>
    </Layout>
  )
}

export default IndexPage
